<template>
  <div class="admin-edit-managed-group">
    <loading-screen :is-loading="isSaving || isDeleting"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Edit Managed Group</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <div class="top-row">
        <div
          class="left"
          :class="{
            'has-custom-avatar':
              managedGroup.hasCustomAvatar && !shouldRemoveExistingAvatar,
          }"
        >
          <a-form-item label="Group Icon">
            <div
              v-if="managedGroup.hasCustomAvatar && !shouldRemoveExistingAvatar && !fileList.length"
              class="custom-avatar"
            >
              <div class="left">
                <chat-thumbnail
                  size="large"
                  :has-custom-avatar="true"
                  :chat-id="managedGroupId"
                  :chat-owner-id="managedGroup.ownerId"
                  :tenant-id="tenantId"
                ></chat-thumbnail>
              </div>
              <div class="right">
                <a-popconfirm
                  title="Are you sure?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="shouldRemoveExistingAvatar = true"
                >
                  <a href="#">Remove Icon</a>
                </a-popconfirm>
              </div>
            </div>

            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to {{ (managedGroup.hasCustomAvatar && !shouldRemoveExistingAvatar || fileList.length) ? 'change' : 'upload' }}
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Group Name">
            <a-input
              size="large"
              v-model="managedGroupLocal.groupName"
            ></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item style="margin-bottom: 0" label="Membership"></a-form-item>
      <membership-selector
        :selected-presence-ids="managedGroupLocal.presenceIds"
        @toggle-presence="togglePresenceSelected"
      ></membership-selector>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import MembershipSelector from "../../../components/Admin/MembershipSelector.vue";
const _ = require("lodash");
import chats from "../../../api/chats";
import ChatThumbnail from "../../../components/Chats/ChatThumbnail.vue";

export default {
  components: { LoadingScreen, MembershipSelector, ChatThumbnail },

  data() {
    return {
      managedGroupLocal: {
        groupName: "",
        presenceIds: [],
      },

      fileList: [],
      shouldRemoveExistingAvatar: false,

      isSaving: false,
      isDeleting: false,
    };
  },

  created() {
    if (!this.managedGroup) {
      this.$message.info("Managed group not found");
      this.$router.push("/admin/managed-groups");
    } else {
      this.managedGroupLocal = {
        groupName: this.managedGroup.displayName,
        presenceIds: _.map(this.managedGroup.membership, "id"),
      };
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/managed-groups");
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      chats
        .deleteChat(
          this.tenantId,
          this.managedGroupId,
          this.myPresenceForOrg.id
        )
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Managed group deleted successfully");
          vm.$router.push("/admin/managed-groups");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting managed group");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.managedGroupLocal.groupName.trim().length == 0) {
        errors.push("Please provide a group name");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      // Update name if need to
      if (this.managedGroup.displayName !== this.managedGroupLocal.groupName) {
        await chats.renameChat(
          this.tenantId,
          this.managedGroupId,
          this.myPresenceForOrg.id,
          this.managedGroupLocal.groupName
        );
      }

      // Get presence ids before any changes (for add/remove logic)
      let originalPresenceIds = _.map(this.managedGroup.membership, "id");

      // Remove any members that need to be removed
      let presencesToRemove = _.difference(
        originalPresenceIds,
        this.managedGroupLocal.presenceIds
      );
      if (presencesToRemove.length) {
        await chats.deleteMembersFromChat(
          this.tenantId,
          this.managedGroupId,
          presencesToRemove,
          this.myPresenceForOrg.id
        );
      }

      // Add any members that need to be added
      let presencesToAdd = _.difference(
        this.managedGroupLocal.presenceIds,
        originalPresenceIds
      );
      if (presencesToAdd.length) {
        await chats.addMembersToChat(
          this.tenantId,
          this.managedGroupId,
          presencesToAdd,
          this.myPresenceForOrg.id
        );
      }

      // Update thumbnail if need to
      if (this.fileList.length) {
        await chats.addChatThumb(
          this.tenantId,
          this.managedGroupId,
          this.myPresenceForOrg.id,
          this.fileList[0]
        );
      } else if (this.shouldRemoveExistingAvatar) {
        await chats.deleteChatThumb(
          this.tenantId,
          this.managedGroupId,
          this.myPresenceForOrg.id
        );
      }

      this.$message.success("Managed group updated");
      this.$router.push("/admin/managed-groups");
    },

    togglePresenceSelected(presenceId) {
      this.managedGroupLocal.presenceIds = _.xor(
        this.managedGroupLocal.presenceIds,
        [presenceId]
      );
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminManagedGroups", {
      managedGroups: "managedGroups",
    }),

    managedGroupId() {
      return this.$route.params.id;
    },

    managedGroup() {
      return _.find(this.managedGroups, {
        id: this.managedGroupId,
      });
    },

    groupMembers() {
      if (this.managedGroup && this.managedGroup.groupMembers) {
        return this.managedGroup.groupMembers;
      }
      return [];
    },

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },
  },
};
</script>

<style lang="scss">
.admin-edit-managed-group {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .has-custom-avatar {
    .ant-form-item-children {
      display: flex !important;
      flex-direction: horizontal;

      .custom-avatar {
        padding-right: 15px;
      }
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .custom-avatar {
    margin-bottom: 15px;
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>